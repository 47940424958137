/**
 * Created by vladislav on 26/10/2022
 */

var CustomerIngredientView = cc.Node.extend({
    ctor: function (ingredient, type) {
        this._super();
        this.type = type || CustomerIngredientView.TYPE_EXCHANGE;
        this.ingredient = ingredient;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(this.getStyles());

        this.createIngredient();
        this.createCheckMark();

        if (this.type !== CustomerIngredientView.TYPE_INFOVIEW) {
            this.createHelp();
        }

        if (this.type !== CustomerIngredientView.TYPE_INFOVIEW && cleverapps.config.debugMode) {
            cleverapps.UI.onClick(this, function () {
                Map2d.currentMap.customers.spawnIngredients([this.ingredient], true);
            }.bind(this));
        }

        this.onAmountChanged(true);

        Map2d.currentMap.customers.on("onRecipesUpdated", this.onAmountChanged.bind(this), this);
    },

    getStyles: function () {
        switch (this.type) {
            case CustomerIngredientView.TYPE_EXCHANGE: return cleverapps.styles.CustomerIngredientViewExchange;
            case CustomerIngredientView.TYPE_INFOVIEW: return cleverapps.styles.CustomerIngredientViewInfoView;
            case CustomerIngredientView.TYPE_UPGRADE: return cleverapps.styles.CustomerIngredientViewUpgrade;
        }
    },

    createIngredient: function () {
        var styles = this.getStyles();

        if (this.ingredient.ingredient) {
            this.icon = Game.currentGame.harvested.getIngredientSmallIcon(this.ingredient.ingredient);
        } else {
            this.icon = UnitView.getUnitImage(this.ingredient.unit, { preferStatic: true, useLibraryAnchors: true });
        }

        if (this.type === CustomerIngredientView.TYPE_EXCHANGE) {
            var bg = new cc.Scale9Sprite(bundles.customer_window.frames.customer_ingredient_bg);
            bg.setContentSize2(this.width, this.height - styles.bgPaddingY);
            bg.setAnchorPoint(0.5, 1);
            bg.setPositionRound(this.width / 2, this.height);
            this.addChild(bg);

            cleverapps.UI.fitToBox(this.icon, { width: bg.width, height: bg.height });
            this.icon.setPosition(this.width / 2, this.height / 2 + styles.icon.offsetY);
            bg.addChild(this.icon);
        } else {
            cleverapps.UI.fitToBox(this.icon, cc.size(this.width, this.height - styles.bgPaddingY));
            this.icon.setPosition(this.width / 2, this.height / 2 + styles.icon.offsetY);
            this.addChild(this.icon);
        }
    },

    createCheckMark: function () {
        var img = this.type === CustomerIngredientView.TYPE_INFOVIEW ? bundles.merge.frames.customer_check_mark : bundles.customer_window.frames.customer_mark;
        this.checkMark = new cc.Sprite(img);
        this.addChild(this.checkMark);
        this.checkMark.setPositionRound(this.getStyles().checkMark);
        this.checkMarkShown = false;
    },

    createHelp: function () {
        var helpParams = this.getHelpParams();
        if (helpParams) {
            helpParams.allowScrollWithFocus = true;
            this.help = cleverapps.UI.createScale9Sprite(bundles.customer_window.frames.customer_magnifier);
            cleverapps.UI.applyHover(this.help);
            cleverapps.UI.onClick(this.help, function () {
                Map2d.currentMap.cancelZoomOut();
                cleverapps.windows.currentWindow().close();

                if (helpParams.ingredient) {
                    UnitLocator.findPrize(helpParams);
                } else if (helpParams.resource) {
                    UnitLocator.find(Map2d.MERGE, { code: helpParams.unit.code }, helpParams);
                } else {
                    UnitLocator.find(Map2d.SPAWN, helpParams.unit, helpParams);
                }
            });
            this.addChild(this.help);
            this.help.setPositionRound(this.getStyles().help);
        }
    },

    onAmountChanged: function (init) {
        if (this.progress) {
            this.progress.removeFromParent();
        }

        this.progress = this.createProgress();
        this.progress.setCascadeOpacityEnabledRecursively(true);
        this.addChild(this.progress);

        this.progress.setPositionRound(this.getStyles().progress);
        cleverapps.UI.fitToBox(this.progress, {
            width: this.width * 0.9
        });

        var isReady = Map2d.currentMap.customers.getLackingAmount(this.ingredient) === 0;
        this.checkMark.setVisible(isReady);
        if (isReady) {
            if (!this.checkMarkShown && !init) {
                this.checkMarkShown = true;
                this.checkMark.setVisible(false);
                this.checkMark.runAction(AnimationsLibrary.showUp(this.checkMark, {
                    fallOffset: 0,
                    fallDuration: 0,
                    scaleX: 1.3,
                    scaleY: 1.3
                }));
            }
        } else {
            this.checkMarkShown = false;
        }
        if (this.help) {
            this.help.setVisible(!isReady);
        }

        this.updateEnabled();
    },

    updateEnabled: function () {
        if (this.type === CustomerIngredientView.TYPE_INFOVIEW) {
            return;
        }

        var enabled = Map2d.currentMap.customers.getLackingAmount(this.ingredient) === 0;

        [this.icon, this.progress].forEach(function (node) {
            if (enabled) {
                node.setOpacity(255);
                node.setColor(new cc.Color(255, 255, 255, 255));
            } else {
                node.setOpacity(160);
                node.setColor(new cc.Color(180, 180, 180, 255));
            }
        });
    },

    createProgress: function () {
        var mini = this.type === CustomerIngredientView.TYPE_INFOVIEW;
        var font = mini ? cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_NOT_READY_TEXT_MINI : cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_NOT_READY_TEXT;
        if (Map2d.currentMap.customers.getLackingAmount(this.ingredient) === 0) {
            font = mini ? cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_READY_TEXT_MINI : cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_READY_TEXT;
        }
        var currentAmount = cleverapps.UI.generateImageText(Map2d.currentMap.customers.getStockAmount(this.ingredient), font);

        font = mini ? cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_TEXT_MINI : cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_TEXT;
        var slash = cleverapps.UI.generateImageText("/", font);
        var requiredAmount = cleverapps.UI.generateImageText(this.ingredient.amount, font);

        return new cleverapps.Layout([currentAmount, slash, requiredAmount], {
            margin: this.getStyles().progress.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    showUp: function () {
        this.setScale(0.3);
        var markToShow = Map2d.currentMap.customers.getLackingAmount(this.ingredient) === 0 ? this.checkMark : this.help;
        if (markToShow) {
            markToShow.setScale(2);
            markToShow.setVisible(false);
        }

        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.3),
            new cc.Show(),
            new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()),
            new cc.CallFunc(function () {
                if (markToShow) {
                    markToShow.runAction(new cc.Sequence(
                        new cc.DelayTime(0.7),
                        new cc.Show(),
                        new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut())
                    ));
                }
            })
        ));
    },

    getHelpParams: function () {
        var helpParams;

        if (this.ingredient.unit) {
            if (Families[this.ingredient.unit.code].type === "campaignorder") {
                helpParams = {
                    unit: this.ingredient.unit,
                    useStageForCustomerSearch: true
                };
            }
            if (Families[this.ingredient.unit.code].locators) {
                helpParams = {
                    unit: this.ingredient.unit,
                    getFindTargets: function () {
                        return Families[this.ingredient.unit.code].locators;
                    }.bind(this)
                };
            }

            if (Families[this.ingredient.unit.code].type.indexOf("resource") !== -1) {
                helpParams = {
                    unit: this.ingredient.unit,
                    resource: true
                };
            }
        }

        if (this.ingredient.ingredient) {
            helpParams = {
                ingredient: this.ingredient.ingredient
            };
        }

        return helpParams;
    }
});

CustomerIngredientView.TYPE_INFOVIEW = 1;
CustomerIngredientView.TYPE_EXCHANGE = 2;
CustomerIngredientView.TYPE_UPGRADE = 3;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CUSTOMER_INGREDIENT_TEXT: {
        size: 42,
        name: "nostroke",
        color: new cc.Color(198, 124, 95, 255)
    },

    CUSTOMER_INGREDIENT_NOT_READY_TEXT: {
        size: 42,
        name: "nostroke",
        color: new cc.Color(250, 79, 75, 255)
    },

    CUSTOMER_INGREDIENT_READY_TEXT: {
        size: 42,
        name: "nostroke",
        color: new cc.Color(40, 184, 14, 255)
    },

    CUSTOMER_INGREDIENT_TEXT_MINI: {
        size: 26,
        name: "nostroke",
        color: new cc.Color(198, 124, 95, 255)
    },

    CUSTOMER_INGREDIENT_NOT_READY_TEXT_MINI: {
        size: 26,
        name: "nostroke",
        color: new cc.Color(250, 79, 75, 255)
    },

    CUSTOMER_INGREDIENT_READY_TEXT_MINI: {
        size: 26,
        name: "nostroke",
        color: new cc.Color(40, 184, 14, 255)
    }
});

cleverapps.styles.CustomerIngredientViewExchange = {
    width: 150,
    height: 214,
    bgPaddingY: 30,

    icon: {
        offsetY: 20
    },

    progress: {
        margin: -5,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 38 }
    },

    help: {
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 10 }
    },

    checkMark: {
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 10 }
    }
};

cleverapps.styles.CustomerIngredientViewInfoView = {
    width: 80,
    height: 80,
    bgPaddingY: 20,

    icon: {
        offsetY: 10
    },

    progress: {
        margin: -4,

        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -9 }
    },

    checkMark: {
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 20 }
    }
};

cleverapps.styles.CustomerIngredientViewUpgrade = {
    width: 150,
    height: 200,
    bgPaddingY: 0,

    icon: {
        offsetY: 20
    },

    progress: {
        margin: -5,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 18 }
    },

    help: {
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 20 }
    },

    checkMark: {
        x: { align: "right", dx: 24 },
        y: { align: "top", dy: 30 }
    }
};